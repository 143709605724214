.page{
  min-width: 1300px;
  padding-bottom: 20px;
  .background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url('./星空.png');
    background-size: 100% 100%;
  }
  .box-fix{
    position: fixed;
    right: 50px;
    width: 264px;
    height: 264px;
    top: 400px;
    @media (max-height: 850px){ 
      right: 32px;
      width: 180px;
      height: 195px;
      top: 420px;
    }
    .title{
      position: absolute;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      top: 16px;
      left: 92px;
      @media ( max-height:850px) { 
        font-size: 15px;
        top: 12px;
        left: 60px;
      }
    }
    .icon{
      display: inline-block;
      position: absolute;
      top: 12px;
      right: 12px;
      color: #ffffff;
      cursor: pointer;
    }
    .pic{
      position: absolute;
      top: 52px;
      left: 52px;
      width: 160px;
      height: 160px;
      background: url(https://oss.huizustore.com/cbdaa688e778442ab8ab1481b9dbf747.png);
      background-size: 100% 100%;
      @media ( max-height:850px) { 
        top: 39px;
        left: 30px;
        width: 120px;
        height: 120px;
        background: url(https://oss.huizustore.com/cbdaa688e778442ab8ab1481b9dbf747.png);
        background-size: 100% 100%;
      }
    }
    .tip{
      position: absolute;
      left: 44px;
      bottom: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      @media ( max-height:850px) { 
        left: 24px;
        bottom: 10px;
        font-size: 12px;
      }
    }
  }
  .modal-back{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 9999;
    .box-modal{
      opacity: 1;
      position: fixed;
      top: 50%;
      left: 50%;
      width: 760px;
      height: 556px;
      margin-left: -380px;
      margin-top: -278px;
      background: #171717;
      border-radius: 8px;
      border: 1px solid rgba(0,0,0,0.25);
      z-index: 1000;
      @media ( max-height:850px) { 
        width: 536px;
        height: 497px;
        margin-left: -268px;
        margin-top: -248.5px;
      }
      .title{
        position: absolute;
        left: 308px;
        top: 24px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        @media ( max-height:850px) { 
          left: 220px;
          top: 32px;
          font-size: 24px;
        }
      }
      .icon{
        position: absolute;
        top: 16px;
        right: 16px;
        color: #FFFFFF;
        cursor: pointer;
        font-size: 16px;
        @media ( max-height:850px) { 
          top: 11px;
          right: 11px;
        }
      }
      .form{
        position: absolute;
        width: 491px;
        left: 127px;
        top: 106px;
        @media ( max-height:850px) { 
          width: 396px;
          left: 70px;
          top: 89px;
        }
        .lable{
          margin-top: 15px;
          width: 143px;
          height: 48px;
          display: inline-block;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          line-height: 48px;
          @media ( max-height:850px) { 
            margin-top: 12px;
            width: 100px;
            height: 28px;
            font-size: 20px;
          }
        }
        .ant-row{
          display: inline-block;
          margin-top: 15px;
          @media ( max-height:850px) { 
            margin-top: 12px;
          }
        }
        .ant-input:focus{
          // border: none;
          box-shadow: none;
        }
        .ant-input{
          &::-webkit-input-placeholder{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.4);
            line-height: 25px;
          }
        }
        .input{
          width: 340px;
          height: 48px;
          border-radius: 6px;
          border: 1px solid #1a1a1a;
          background: rgba(255, 255, 255, 0.11);
          color:  #ffffff;
          @media ( max-height:850px) { 
            width: 296px;
            height: 48px;
          }
          // &:hover{
          //   background: #000000;
          // }
        }
        .ant-btn{
          border-color: #3B6FEF;
        }
        .button{
          margin-left: 173px;
          width: 160px;
          height: 56px;
          background: #0091FF;
          border-radius: 8px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          line-height: 50px;
          @media ( max-height:850px) { 
            margin-top: -10px;
            margin-left: 128px;
            width: 140px;
            height: 48px;
            line-height: 33px;
          }
        }
      }
    }
  }
  .nav{
    position: fixed;
    min-width: 1300px;
    top: 0;
    display: flex;
    z-index: 10;
    @media ( min-height:850px) { 
      width: 100%;
      height: 104px;
    }
    @media ( max-height:850px) { 
      width: 100%;
      height: 88px;
    }
    .nav-logo{
      float: left;
      margin-top: 28px;
      margin-left: 80px;
      width: 128px;
      height: 48px;
      opacity: 1;
      background-image: url(https://oss.huizustore.com/9059b6097d8541e5b3ebcd0aa9a952d5.png);
      background-size: 100% 100%;
      @media ( max-height:850px) { 
        margin-top:25px;
        width: 90px;
        height: 38px;
        background-image: url(https://oss.huizustore.com/9059b6097d8541e5b3ebcd0aa9a952d5.png);
        background-size: 100% 100%;
      }
    }
    .nav-box{
      flex: 1;
    }
    .nav-nav{
      float: right;
      margin-top: 24px;
      margin-right: 96px;
      width: 568px;
      height: 56px;
      @media ( max-height:850px) { 
        margin-top: 16px;
      }
      .nav-button{
        float: right;
        margin-top: 8px;
        width: 120px;
        height:40px ;
        line-height: 40px;
        background: #0091FF;
        border-radius: 8px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
      }
      .nav-title{
        float: right;
        margin-top: 16px;
        margin-right: 96px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.6);
        cursor: pointer;
        &:hover{
          color: #ffffff;
        }
      }
    }
  }
  .header{
    display: flex;
    padding-top: 280px;
    width: 100%;
    height: 600px;
    text-align: center;
    @media ( max-height:850px) { 
      padding-top: 200px;
    }
    // .header-left{
    //   width: 168px;
    //   height: 600px;
    // }
    .header-center{
      flex: 1;
      .tip-box{
        margin: 0 auto;
        width: 100%;
        height: 72px;
        padding-left: 168px;
        padding-right: 232px;
        .tip-green{
          float: left;
          width: 224px;
          height: 72px;
          background: url(https://oss.huizustore.com/14d9c1275dc84ce791d803c8ab34339b.png);
          background-size: 100% 100%;
        }
        .tip-biue{
          float: right;
          width: 224px;
          height: 72px;
          background: url(https://oss.huizustore.com/91850ca542aa44c38ab1129d3a9966aa.png);
          background-size: 100% 100%;
        }
      }
      .title-box{
        margin: 0 auto;
        padding-top: 32px;
        width: 970px;
        height: 130px;
        .triangle{
          float: left;
          width: 41px;
          height: 41px;
          background: url(https://oss.huizustore.com/220d718cd62e41e993298f280c0c5971.png);
          background-size: 100% 100%;
        }
        .title{
          float: left;
          font-size: 72px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
        }
        .circle{
          float: left;
          width: 20px;
          height: 20px;
          background: url(https://oss.huizustore.com/839124fb9c8441419f61cbed715bb4b7.png);
          background-size: 100% 100%;
        }
        .circle1{
          float: left;
          margin-top: 16px;
          width: 11px;
          height: 11px;
          background: url(https://oss.huizustore.com/839124fb9c8441419f61cbed715bb4b7.png);
          background-size: 100% 100%;
        }
      }
      .line{
        margin: 0 auto;
        padding-top: 16px;
        width: 144px;
        height: 32px;
        .line-icon{
          width: 144px;
          height: 16px;
          background: url(https://oss.huizustore.com/85c302f636d24856a3093c53d6d5d016.png);
          background-size: 100% 100%;
        }
      }
      .tip{
        margin: 0 auto;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(123, 123, 123, 0.8);
      }
      .button-box{
        margin: 0 auto;
        padding-top: 40px;
        width: 192px;
        height: 120px;
        .button{
          margin: 0 auto;
          width: 192px;
          height: 80px;
          background: #0091FF;
          border-radius: 8px;
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          text-align: center;
          line-height: 80px;
          cursor: pointer;
        }
      }
      .tip-boxs{
        margin: 0 auto;
        width: 100%;
        height: 72px;
        padding-left: 86px;
        padding-right: 120px;
        .tip-purple{
          float: left;
          // margin-left: -60px;
          width: 224px;
          height: 72px;
          background: url(https://oss.huizustore.com/414a7937b71741be9f849448fb882fce.png);
          background-size: 100% 100%;
        }
        .tip-yellow{
          float: right;
          // margin-right: -80px;
          width: 224px;
          height: 72px;
          background: url(https://oss.huizustore.com/59c501cfca464237bc82c978992f7b51.png);
          background-size: 100% 100%;
        }
      }
    }
    // .header-right{
    //   width: 232px;
    //   height: 600px;
    // }

  }
  .entry-box1{
    width: 100%;
    height: 309px;
  }
  .product{
    display: flex;
    padding-top: 200px;
    width: 100%;
    height: 600px;
    .product-left{
      width: 160px;
      height: 600px;
    }
    .product-center{
      flex: 1;
      .title{
        font-size: 72px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        .purpleLine{
          margin-top: -56px;
          margin-left: 72px;
          width: 289px;
          height: 32px;
          background: #725AF9;
        }
        .green{
          margin-top: -64px;
          margin-left: 580px;
          width: 16px;
          height: 16px;
          background: url(https://oss.huizustore.com/fb48e38b0dbe498e957dda6bc79f6d05.png);
          background-size: 100% 100%;
        }
        .green1{
          margin-top: 10px;
          margin-left: 600px;
          width: 8px;
          height: 8px;
          background: url(https://oss.huizustore.com/fb48e38b0dbe498e957dda6bc79f6d05.png);
          background-size: 100% 100%;
        }
      }
      .tip{
        margin-top: 88px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7B7B7B;
      }
      .commit{
        margin-top: 80px;
        .commitItem{
          float: left;
          width: 25%;
          padding-left: 25px;
          padding-right: 25px;
          .icon{
            width: 96px;
            height: 96px;
            background: url(https://oss.huizustore.com/ecfa821320db4793886e90d63ec025c0.png);
            background-size: 100% 100%;
          }
          .icon1{
            width: 96px;
            height: 96px;
            background: url(https://oss.huizustore.com/55eb1c9172b34e4b8b499aa396cc2e28.png);
            background-size: 100% 100%;
          }
          .icon2{
            width: 96px;
            height: 96px;
            background: url(https://oss.huizustore.com/3542fdc88d064d7a954c63ea697aa6c7.png);
            background-size: 100% 100%;
          }
          .icon3{
            width: 96px;
            height: 96px;
            background: url(https://oss.huizustore.com/8050bccd87824d04a5e472a1afc16d2e.png);
            background-size: 100% 100%;
          }
          .title{
            margin-top: 16px;
            font-size: 26px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
          }
          .tip{
            margin-top: 16px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7B7B7B;
          }
        }
      }
    }
    .product-right{
      width: 160px;
      height: 600px;
    }
  }
  .entry-box2{
    width: 100%;
    height: 360px;
  }
  .picture{
    display: flex;
    width: 1080px;
    margin: 0 auto;
    height: 630px;
    text-align: center;
    // .pic-left{
    //   width: 160px;
    //   height: 630px;
    // }
    .pic-center{
      flex: 1;
      margin: 0 auto;
      .pic{
        float: left;
        // width: 70%;
        height: 630px;
        // background: wheat;
        .content{
          margin-top: 60px;
          width: 764px;
          height: 441px;
          // .carousel1{
          //   background: url(https://oss.huizustore.com/cc9b134cdb3a4360ba41d192a47d7847.png);
          //   background-size: 100% 100%;
          // }
          // .carousel2{
          //   background: url(https://oss.huizustore.com/578d54772a074101b2dc19b94d1dd6bd.png);
          //   background-size: 100% 100%;
          // }
          // .carousel3{
          //   background: url(https://oss.huizustore.com/fcfd8c3b44024a998ca51b554225e7e0.png);
          //   background-size: 100% 100%;
          // }
        }
      }
      .pic-box{
        float: left;
        width: 36px;
        height: 630px;
      }
      .pic-content{
        float: left;
        text-align: left;
        letter-spacing: 3px;
        margin-top: 50px;
        // margin-left: 16px;
        width: 280px;
        // width: 25%;
        height: 576px;
        // background: yellow;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 50px;
        color: #FFFFFF;
      }
    }
    // .pic-right{
    //   width: 160px;
    //   height: 630px;
    // }
  }
  .entry-box3{
    width: 100%;
    height: 160px;
  }
  .aboutUs{
    display: flex;
    padding-top: 200px;
    width: 1080px;
    margin: 0 auto;
    height: 1000px;
    // .Us-left{
    //   width: 160px;
    //   height: 600px;
    // }
    .Us-center{
      // width: 1080px;
      // margin: 0 auto;
      flex: 1;
      // background: chocolate;
      .title{
        // margin: 0 auto;
        text-align: center;
        font-size: 72px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
      .tip{
        margin-top: 24px;
        text-align: center;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7B7B7B;
      }
      .content-title{
        // width: 1080px;
        margin-top: 64px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
      .content{
        margin-top: 24px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7B7B7B;
      }
      .pic-box{
        margin-top: 40px;
        // display: flex;
        width: 100%;
        height: 440px;
        .pic-left{
          float: left;
          width: 60%;
          height: 440px;
          // background: chocolate;
          .content{
            // margin: 0 auto;
            width: 528px;
            height: 264px;
            background: url(https://oss.huizustore.com/23ae04b37e2a41a1aa52abac3f9c3728.png);
            background-size: 100% 100%;
            z-index: 5;
          }
        }
        .pic-right{
          float: left;
          width: 40%;
          height: 440px;
          // background: coral;
          .content{
            float: right;
            // margin: 0 auto;
            width: 408px;
            height: 264px;
            background: url(https://oss.huizustore.com/9084b5ba666840d98beb4134c9a052aa.png);
            background-size: 100% 100%;
            z-index: 5;
          }
        }
      }
    }
    // .Us-right{
    //   width: 160px;
    //   height: 600px;
    // }
  }
  .entry-box4{
    width: 100%;
    height: 240px;
  }
  .action{
    .title{
      text-align: center;
      font-size: 72px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }
    .tip{
      margin-top: 24px;
      margin-bottom: 40px;
      text-align: center;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(123, 123, 123, 0.8);
    }
    .button{
      margin: 0 auto;
      text-align: center;
      width: 192px;
      height: 80px;
      background: #0091FF;
      border-radius: 8px;
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 80px;
      cursor: pointer;
    }
  }
  .entry-box5{
    width: 100%;
    height: 320px;
  }
  .footer{
    .line{
      width: 100%;
      height: 1px;
      background: #313131;
    }
    .content{
      margin-top: 32px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7B7B7B;
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;